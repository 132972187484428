import React from 'react'
import footerlogo from '../Images/footerlogo.png'

function Footer() {
  return (
    <div className='footer'>
      <div  style={{bottom:'0px',left:'0px',right:'0px'}}>
      <footer className='text-white' style={{backgroundColor: '#fc9f1c'}}>
        <div className='container mx-auto'>
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3">
           
           {/* footer column1 */}
           <div className='col mt-5 ps-5'>
             <ul className='list-unstyled'>
               <li><p className='h3 fw-bold mb-4' style={{fontFamily: 'Lemon'}}>Adresse</p></li>
               <li>
                 <p>10 Rond Point de la Corbinière <br/>44400 Reze, Nantes,<br/>France.</p>
               </li>
               <li><p>+33 7 49 80 90 42</p></li>
               <li><p>Royalbangla2000@gmail.com</p></li>               
             </ul>
           </div>

           {/* footer column2 */}
           <div className='col p-5'>
             <div>
               <img src={footerlogo} className='w-100 footerlogo' />
             </div>
           </div>

           {/* footer column3 */}
           <div className='col ps-5 mt-lg-5'>
             <p className='h3 fw-bold mb-4 footercolthree' style={{fontFamily: 'Lemon'}}>Suivez-nous</p>
             <div className='flex d-flex'>
               <div><i className="fa-brands fa-square-facebook fa-2xl" style={{color: '#0b57da'}}></i></div>
               <div><i className="fa-brands fa-square-instagram fa-2xl ms-2" style={{color: '#e50688'}}></i></div>
             </div>
           </div>
    
        </div>
        </div>
        <div className='container'>
        <hr style={{border:'1px solid white', marginBottom:'0px'}}></hr>
        <p className='text-center p-3 m-0'>Fabriqué par <span className='fw-bold'>Arcane</span> </p>
        </div>
      </footer>
       
    </div>
    </div>
  )
}

export default Footer