import React from 'react'
import biryani from '../Images/biryani.jpg'
import cocktails from '../Images/cocktails.jpg'
import paneer from '../Images/paneer.jpg'
import cafegourmand from '../Images/cafegourmand.jpg'
import chairs from '../Images/chairs.jpg'
import tables from '../Images/chairs2.jpg'
import dishes from '../Images/dishes.jpg'
function Gallery() {
    return (
        <div>
            <div className='row row-cols-2 row-cols-lg-4 g-0'>
                <div className='col p-1'>
                    <img src={dishes} className='w-100' />
                </div>
                <div className='col p-1'>
                    <img src={chairs} className='w-100' />
                </div>
                <div className='col p-1'>
                    <img src={tables} className='w-100' />
                </div>
                <div className='col p-1'>
                    <img src={cocktails} className='w-100' />
                </div>
            </div>
        </div>
    )
}

export default Gallery