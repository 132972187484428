import React from 'react'
import Dishes from './Dishes'
import Menumainbanner from './Menumainbanner'
import Combo from './Combo'

function Menu() {
  return (
    <div>
      <div>
        <div className='menubannerrelative' >
            <Menumainbanner />
        </div>
        <div className='menubannerabsolute'>
          <Combo/>
        </div>
      </div>

      <div>
        <Dishes />
      </div>
    </div>
  )
}

export default Menu