import logo from './logo.svg';
import './App.css';
import Navroutecomponent from './Components/Navroutecomponent';
import {Route,Routes} from 'react-router-dom'
import Home from './Components/Home';
import Menu from './Components/Menu';
import Contact from './Components/Contact';

function App() {
  return (
    <div>
      <div id='navtop' >
      <Navroutecomponent/>
      </div>

      <Routes>
        <Route path='' element={<Home/>} />
        <Route path='menu' element={<Menu/>} />
        <Route path='contact' element={<Contact/>} />
      </Routes>
    </div>
  );
}

export default App;
