import React from 'react'
import contactbannerbg from '../Images/contactbannerbg.png'

function Contactmainbanner() {
  return (
    <div>
      <div className='row row-cols-1 contactbanner' style={{ backgroundImage: `url(${contactbannerbg})` }}>

        <div className='text-white' style={{fontFamily:'Gilda Display'}}>
          <p className='contactheader'>CONTACTEZ-NOUS</p>
        </div>

      </div>
    </div>
  )
}

export default Contactmainbanner