import React from 'react'
import chickentikka from '../Images/chickentikka.jpg'
import daalsoup from '../Images/daalsoup.jpeg'
import raita from '../Images/raita.jpg'

function Ourstartersmenu() {
    return (
        <div>
            {/* row1 */}
            <h1 className='text-center my-3' style={{ fontFamily: 'Lemon' }}>NOS ENTRÉES</h1>
            <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>

                <div className='col p-4' >
                    <div>

                        <div className='row row-cols-2 mt-3' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' ,borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>RAITA</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Crudités de légumes trais au yaourt.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>5,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                                
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>DAAL SOUPS</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Lentilles cuisinées aux tomates et épices douces</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>5,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>SAMOUSSA AUX LÉGUMES</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Feuilletas farcis aux légumes (pommes de terre et petits pois avec épices mélangées).</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>5,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>ASSORTIMENT DE PAKORAS </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Mélange de beignets de légumes (oignons,aubergines et pommes de terre)</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>5,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>ROYAL TIKKA </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Cuisse de poulet marinée dans un mélange d’épices,puis grillée au Tandoor.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>5,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>SAUMON TIKKA</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Fillet de saumon en marinade de d'épice mélangées,grille au tandoor</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>7,00€</h5></div>
                        </div>


                    </div>
                </div>

                <div className='col p-4'>
                    <div className='pb-4 d-block'>
                        <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade menuimg" data-bs-ride="carousel">
                            <div className="carousel-inner menuimg" style={{backgroundColor:'green',borderRadius:'60px',zIndex:'0'}}>
                                <div className="carousel-item active">
                                    <img src={daalsoup} className="d-block w-100" style={{height:'100%'}} />
                                </div>
                                <div className="carousel-item">
                                    <img src={chickentikka} className="d-block w-100" style={{height:'100%'}} />
                                </div>
                                <div className="carousel-item">
                                    <img src={raita} className="d-block w-100" style={{height:'100%'}} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row row-cols-2 mt-2' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>POULET TANDOORI</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Cuisse de Poulet marinée dans un mélange d'épice,puis grille au tandoor</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>6,00€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>ASSIETTE MAHARAJA (POUR 2 PERSONNES)</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Tikka au Poulet et au bœuf, (avec pakoras de beignet et citron)</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>12,90€</h5></div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Ourstartersmenu