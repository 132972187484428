import React,{ useRef } from 'react'
import { useForm } from 'react-hook-form'
import {Form,FloatingLabel} from 'react-bootstrap'
import Footer from './Footer'
import { motion } from 'framer-motion'
import emailjs from '@emailjs/browser'
import Contactmainbanner from './Contactmainbanner'

function Contact() {

    const { register, handleSubmit } = useForm()
    const form = useRef();

    const onFormSubmit = (details) => {
        emailjs.sendForm('service_nni5xhn', 'template_akcf6yj', form.current, 'z_SZk3E9ffoyP8oNh')
      .then((result) => {
          console.log(result.text);
          console.log('sent')
      }, (error) => {
          console.log(error.text);
      });
    }

    return (
        <div>

            <div>
                <Contactmainbanner />
            </div>
        
        <div className='container'>
            <h1 className='my-5' style={{fontFamily: 'Lemon'}} >ENTRER EN CONTACT !</h1>

            {/* contact form */}
            <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>
                <div className='col mx-auto'>
                    <form ref={form} onSubmit={handleSubmit(onFormSubmit)}>

                        {/* name */}
                        <FloatingLabel style={{color:'grey'}} controlId="floatingName" label="Nom" className='mb-3' >
                        <Form.Control type="text" placeholder='Nom' style={{border:'1px solid #B6C5CD', borderRadius:'0px'}} {...register("name", { required: true })}/>
                        </FloatingLabel>

                        {/* email */}
                        <FloatingLabel style={{color:'grey'}} controlId="floatingInput" label="E-mail" className="mb-3">
                        <Form.Control  type="email" placeholder='text@domain.com' style={{border:'1px solid #B6C5CD', borderRadius:'0px'}} {...register("email", { required: true })} />
                        </FloatingLabel>

                        {/* phone number */}
                        <FloatingLabel style={{color:'grey'}} controlId="floatingPassword" label="Mobile" className='mb-3'>
                        <Form.Control type="text" placeholder='Mobile' style={{border:'1px solid #B6C5CD', borderRadius:'0px'}} {...register("mobile", { required: true })}/>
                        </FloatingLabel>

                        {/* message */}
                        <FloatingLabel style={{color:'grey'}} controlId="floatingTextarea2" label="Message" className='mb-3'>
                        <Form.Control as="textarea" placeholder='Type your message here' style={{ height: '100px',border:'1px solid #B6C5CD', borderRadius:'0px' }} {...register("message", { required: true })}/>
                        </FloatingLabel>

                        {/* send */}
                        <button className='text-center btn fw-bold' type='submit' style={{color:'#fc9f1c',border:'1px solid #fc9f1c'}} >Envoyer</button>
                    </form>
                </div>
                <div className='col ps-5 mt-5 mt-lg-0'>
                    {/* address */}
                    <div className='row row-cols-2'>
                        <div className='col-1' style={{color:'#fc9f1c'}}><i className="fa-solid fa-location-dot fa-beat-fade fa-xl"></i></div>
                        <div className='col'>
                            <p style={{fontFamily:'Lemon'}}>Adresse</p>
                            <p >10 Rond Point de la Corbinière, <br/>44400 Reze, Nantes,<br/>France.</p>
                        </div>
                    </div>

                    {/* phone */}
                    <div className='row row-cols-2'>
                        <div className='col-1' style={{color:'#fc9f1c'}}><i className="fa-solid fa-phone fa-shake fa-xl"></i></div>
                        <div className='col'>
                            <p style={{fontFamily:'Lemon'}}>Téléphone</p>
                            <p >+33 7 49 80 90 42</p>
                        </div>
                    </div>

                    {/* email us */}
                    <div className='row row-cols-2 mt-2'>
                        <div className='col-1' style={{color:'#fc9f1c'}}><i className="fa-solid fa-envelope fa-bounce fa-xl"></i></div>
                        <div className='col'>
                            <p style={{fontFamily:'Lemon'}}>E-mail</p>
                            <p >Royalbangla2000@gmail.com</p>
                        </div>
                    </div>
                
                
                </div>
            </div>

            <div className='row mt-5'>
                {/* embedded map location */}
                <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10851.482441236194!2d-1.5467864!3d47.1603885!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4805e97051ae8eaf%3A0x94e85ca693097bfd!2sRoyal%20Bangla!5e0!3m2!1sen!2sin!4v1695929474473!5m2!1sen!2sin"
                        width="100%"
                        height="300"
                        style={{ border: '0'}}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className='mb-3'>
                    </iframe>
                </div>
            </div>

        </div>
        {/* footer */}
        <div>
         <Footer />
        </div>

        </div>
    )
}

export default Contact