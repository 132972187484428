import React from 'react'
import { Nav, Navbar, Container, NavbarBrand } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Outlet } from 'react-router-dom'
import navlogo from '../Images/navlogo.png'

function Navroutecomponent() {
    return (
        <div>
            <Navbar className='row navholder' expand='lg' collapseOnSelect style={{ backgroundColor: 'white'}}>
                <Container fluid>
                    <Navbar.Brand className='col ms-4 mb-0'><img src={navlogo} className='navlogo' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav className='col d-flex justify-content-evenly'>
                            <LinkContainer to='/'>
                                <Nav.Link><span className='ms-3 ms-lg-0' style={{ color: '#fc9f1c', fontFamily: 'Lemon' }}>Home</span></Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/menu'>
                                <Nav.Link><span className='ms-3 ms-lg-0' style={{ color: '#fc9f1c', fontFamily: 'Lemon' }}>Menu</span></Nav.Link>
                            </LinkContainer>
                            <LinkContainer to='/contact'>
                                <Nav.Link><span className='ms-3 ms-lg-0' style={{ color: '#fc9f1c', fontFamily: 'Lemon' }}>Contact</span></Nav.Link>
                            </LinkContainer>
                        </Nav>

                    </Navbar.Collapse>

                </Container>
            </Navbar>
            <div style={{width:'100%'}}>
                <Outlet />
            </div>
        </div>
    )
}

export default Navroutecomponent