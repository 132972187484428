import React from 'react'
import backgroundorange from '../Images/backgroundorange.png'
import bannerbiryani from '../Images/bannerbiryani.png'
import { motion } from 'framer-motion'

function animatedhomebanner() {
  return (
    <div>
      <div className='row row-cols-2 bannerholder animehome' style={{backgroundImage:`url(${backgroundorange})`}} >
        <div className='col-4'>
          <motion.div
          initial={{y:650}}
          animate={{y:0}}
          transition={{delay:0.3, type:'spring',stiffness: 400, duration:1.5}}>
            <img src={bannerbiryani} className='animebanner' />
          </motion.div>
        </div>
        <div className='col-8 pe-0'>
          <div className='p-3 bannerheaderdiv' style={{color:'white', fontFamily:'Gilda Display'}}>
            <p className='bannerheader' >CÉLÉBRER  VIE , AMOUR</p>
            <p className='bannerheader text-center'> & CUISINE INDIENNE !</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default animatedhomebanner