import React from 'react'
import cafegourmand from '../Images/cafegourmand.jpg'

function Desserts() {
  return (
    <div className='container'>
            <h1 className='text-center pt-5' style={{ fontFamily: 'Lemon' }}>DESSERTS</h1>
            <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>
                <div className='col text-center'>
                    <div className='my-5 pt-1'>
                        <img src={cafegourmand} width='98%' />
                    </div>
                </div>
                <div className='col'>

                    <div className='p-4 my-5' style={{border:'2px solid black'}}>

                        <div className='row row-cols-2 ' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>LASSI MANGUE</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Yaourt parfumé à la mangue, à la cardamome, aux amandes et au lait.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>5,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>LASSI ROSE</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Yaourt parfumé à la sirop de Rose, à la cardamome, aux amandes et au lait.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>5,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>LASSI BANANE</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Yaourt parfumé à la Banane, à la cardamome, aux amandes et au lait.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>5,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>TIRAMISU À LA MANGUE</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Génoise fondante recouverte de mousse de mangue.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>6,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>KULFI </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Glace maison à la cardamome et pistache.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>6,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>FARANDOLE DE SORBETS EXOTIQUES</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>2 boules de glaces au choix (mangue, cassis, passion, citron).</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>6,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>CAFÉ GOURMAND</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Café ou Décaféiné servi avec gateaux farine de blé, glace vanille et mousse de mangue.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>7,00€</h5></div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
  )
}

export default Desserts