import React from 'react'
import cocktails from '../Images/cocktails.jpg'

function Cooldrinks() {
    return (
        <div>
            {/* row1 */}
            <h1 className='text-center my-3' style={{ fontFamily: 'Lemon' }}>NOS BOISSONS</h1>
            <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>

                <div className='col p-4' >
                    <h4 className='text-center my-4' style={{ fontFamily: 'Lemon' }}>Les Cocktails</h4>
                    <div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>

                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>ROYAL BUZZ</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Vodka, coulis de pêche, jus de mangue et d’orange,lemonade et citron frais.</p>
                            </div>

                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>4,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>

                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>DHAKA DHAMAKA (SANS ALCOOL)</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Jus d’orange, pêche mangue, sirop spicy et cannelle,limonade et citron frais.</p>
                            </div>

                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>4,50€</h5></div>
                        </div>

                        <h4 className='text-center my-4 mt-5' style={{ fontFamily: 'Lemon' }}>Biéres</h4>


                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>KINGFISHER <span className='fw-normal' style={{ fontSize: '14px'}}>(BIERE INDIENNE)</span></h5>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>5,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>HEINEKEN</h5>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>4,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>1664</h5>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>4,00€</h5></div>
                        </div>
                    
                    <h4 className='my-4 mt-5' style={{ fontFamily: 'Lemon' }}>PICHET DE VINS(ROSÉ, BLANC, ROUGE)</h4>


                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>12 CL</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>3,50€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>25 CL</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>7,00€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>50 CL</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>10,00€</h5></div>
                    </div>


                        
                        <h4 className='text-center my-4 mt-5' style={{ fontFamily: 'Lemon' }}>Apéritifs</h4>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>KIR VINS BLANC <span className='fw-normal' style={{ fontSize: '14px'}}>(CASSIS, PÊCHE, MURE, ROSE)</span></h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>4,50€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>KIR ROYAL  <span className='fw-normal' style={{ fontSize: '14px'}}>(CHAMPAGNE)</span></h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>5,50€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>PASTIS  <span className='fw-normal' style={{ fontSize: '14px'}}>(4 CL)</span></h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>4,10€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>MARTINI BLANC</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>4,10€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>PORTO ROUGE</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>4,10€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>WHISKY <span className='fw-normal' style={{ fontSize: '14px'}}>(4 CL)</span></h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>5,40€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>WHISKY SUPÉRIEUR</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>6,40€</h5></div>
                    </div>


                        


                    </div>
                </div>

                <div className='col p-4'>
                    <div className='pb-4 d-block'>
                            <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade menuimg" data-bs-ride="carousel">
                                <div className="carousel-inner menuimg" style={{backgroundColor:'green',borderRadius:'60px',zIndex:'0'}}>
                                    <div className="carousel-item active">
                                        <img src={cocktails} className="d-block w-100" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={cocktails} className="d-block w-100" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={cocktails} className="d-block w-100" />
                                    </div>
                                </div>
                            </div>
                    </div>

                    
                    <h4 className='text-center my-4 mt-3' style={{ fontFamily: 'Lemon' }}>Sans Alcool</h4>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>JUS DE FRUITS <span className='fw-normal' style={{ fontSize: '14px'}}>(ORANGE, MANGUE, ANANAS)</span></h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>3,90€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>ORANGINA</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>3,90€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>SCHWEPPES TONIC</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>3,90€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>COCA COLA</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>3,90€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>EAU PLATE 1L</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>3,90€</h5></div>
                    </div>

                    <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                        {/* dish & description*/}
                        <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                            <h5 className='fw-bold'>EAU GAZEUX 1L</h5>
                        </div>
                        {/* price */}
                        <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>3,90€</h5></div>
                    </div>

                    <h4 className='text-center my-4 mt-5' style={{ fontFamily: 'Lemon' }}>Boissons Chaud</h4>


                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>CAFÉ OU DÉCAFÉINÉ</h5>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>1,90€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>DOUBLE EXPRESSO</h5>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>2,40€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>CAFÉ CRÈME</h5>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>2,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>THÉ</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>(NOIR, JASMIN, CARDAMOME, MENTHE, ROSE)</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>3,90€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>THÉ CHAI</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>(CHAI INDIENNE AVEC CARDAMOME,LAIT ET GINGIMBRE)</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>4,90€</h5></div>
                        </div>



                </div>

            </div>

            

        </div>
    )
}

export default Cooldrinks