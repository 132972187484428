import React from 'react'
import naan from '../Images/butternaan.jpg'
import paratha from '../Images/paratha.png'
import rizpilaf from '../Images/rizpilaf.avif'

function Indianbreads() {
  return (
    <div>

         {/* row1 */}
         <h1 className='text-center my-3' style={{ fontFamily: 'Lemon' }}>NOS ACCOMPAGNEMENTS</h1>
            <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>

                <div className='col p-4' >
                    <div>

                        <div className='row row-cols-2 mt-3' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' ,borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>NAAN NATURE</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Pain à la farine de blé, cuit dans le four tandoor.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>2,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                                
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>NAAN AU FROMAGE</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Pain à la farine de blé, cuit dans le four tandoor et farci avant cuisson de fromage et de beurre</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>3,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>NAAN À L’AIL </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Pain à la farine de blé farci d’ail frais, cuit dans le four tandoor et parsemé de coriandre fraiche et de beurre.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>3,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>RIZ PILAF</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Pain à la farine de blé, cuit dans le four tandoor et farci avant cuisson de fromage et de beurre</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>3,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>KULCHA </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Pain à la farine de blé, farci de purée de pomme de terre et des petits pois aux épices, cuit dans le four tandoor et parsemé de coriandre fraiche et de beurre.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>4,50€</h5></div>
                        </div>


                    </div>
                </div>

                <div className='col p-4'>
                    <div className='pb-4 mt-3'>
                        <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade menuimg" data-bs-ride="carousel">
                            <div className="carousel-inner menuimg" style={{backgroundColor:'green',borderRadius:'60px',zIndex:'0'}}>
                                <div className="carousel-item active">
                                    <img src={paratha} className="d-block w-100" style={{height:'100%'}}/>
                                </div>
                                <div className="carousel-item">
                                    <img src={naan} className="d-block w-100" style={{height:'100%'}}/>
                                </div>
                                <div className="carousel-item">
                                    <img src={rizpilaf} className="d-block riz" style={{height:'100%'}}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row row-cols-2 mt-4' style={{color:'grey'}}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px',borderBottom:'2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>PARATHA AUX OIGNONS</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Pain à la farine de blé, cuit dans le four tandoor.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{borderBottom:'2px solid #fc9f1c'}}><h5 className='text-end fw-bold'>2,00€</h5></div>
                        </div>

           </div>
        
        </div>

    </div>
  )
}

export default Indianbreads