import React from 'react'
import Ourstartersmenu from './Ourstartersmenu';
import Ourdishesmenu from './Ourdishesmenu';
import Ourvegetarianmenu from './Ourvegetarianmenu';
import Ourbiryanidishes from './Ourbiryanidishes';
import Indianbreads from './Indianbreads';
import Desserts from './Desserts';
import Cooldrinks from './Cooldrinks';
import Footer from './Footer';

import { Link } from 'react-scroll';

function Dishes() {


    return (

        <div>
            <div className='container mt-4 stickynavbar' style={{ position: 'sticky', top: '10px',zIndex:'1', backgroundColor:'#fc9f1c', borderRadius:'30px'}}>
            <ul className="nav justify-content-evenly" >
                <li className="nav-item p-3 btn" style={{ color:'white', fontFamily:'Lemon' }}>
                    <Link to='starters' smooth={true} offset={-90} duration={500}>ENTRÉES</Link>
                </li>

                <li className="nav-item p-3 btn" style={{ color:'white', fontFamily:'Lemon' }}>
                    <Link to='vegetarian' smooth={true} offset={-40} duration={500}>VÉGÉTARIENS</Link>
                </li>

                <li className="nav-item p-3 btn" style={{ color:'white', fontFamily:'Lemon' }}>
                    <Link to='maindish' smooth={true} offset={-90} duration={500}>PLATS</Link>
                </li>

                <li className="nav-item p-3 btn" style={{ color:'white', fontFamily:'Lemon' }}>
                    <Link to='biryanis' smooth={true} offset={-40} duration={500}>BRIYANIS</Link>
                </li>

                <li className="nav-item p-3 btn" style={{ color:'white', fontFamily:'Lemon' }}>
                    <Link to='breads' smooth={true} offset={-90} duration={500}>ACCOMPAGNEMENTS</Link>
                </li>

                <li className="nav-item p-3 btn" style={{ color:'white', fontFamily:'Lemon' }}>
                    <Link to='desserts' smooth={true} offset={-40} duration={500}>DESSERTS</Link>
                </li>
                
                <li className="nav-item p-3 btn" style={{ color:'white', fontFamily:'Lemon' }}>
                    <Link to='beverages' smooth={true} offset={-90} duration={500}>BOISSONS</Link>
                </li>
            </ul>
            </div>

            <div className='mt-5'>
                {/* our starters */}
                <div className='container' id='starters'>
                    <Ourstartersmenu />
                </div>


                {/* our vegetarian */}
                <div className='my-5' style={{ backgroundColor: '#FFD580' }} id='vegetarian'>
                    <Ourvegetarianmenu />
                </div>


                {/* our dishes */}
                <div className='container' id='maindish'>
                    <Ourdishesmenu />
                </div>


                {/* our biryanis */}
                <div className='my-5' style={{ backgroundColor: '#FFD580' }} id='biryanis'>
                    <Ourbiryanidishes />
                </div>

                {/* our ACCOMPAGNEMENTS */}
                <div className='container' id='breads'>
                    <Indianbreads />
                </div>

                {/* our desserts */}
                <div className='my-5' style={{ backgroundColor: '#FFD580' }} id='desserts'>
                    <Desserts />
                </div>

                {/* our cooldrinks */}
                <div className='container' id='beverages'>
                    <Cooldrinks />
                </div>

                 {/* back to top  */}
                <div className='d-flex justify-content-end me-3 pb-3' style={{ position: 'sticky', bottom: '5px' }} >
                    <button className='btn p-3 ' style={{ color: 'orange', borderRadius: '50%', border: '1px solid orange' }}>
                        <Link to='navtop' smooth={true} offset={0} duration={300}><i className="fa-regular fa-circle-up fa-bounce fa-2xl"></i></Link>
                    </button>
                </div>
                

                {/* footer  */}
                <div>
                    <Footer />
                </div>

            </div>

        </div>
    )
}

export default Dishes