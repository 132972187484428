import React from 'react'
import outdoorbanner from '../Images/outdoorbanner.jpg'
import hotelbanner from '../Images/hotelbanner.jpg'

function Corouselbar() {
  return (
    <div>
        <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner" >
          <div className="carousel-item active">
            <img src={outdoorbanner} className="d-block w-100" />
          </div>
          <div className="carousel-item">
            <img src={hotelbanner} className="d-block w-100" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Corouselbar