import React from 'react'
import menubgbanner from '../Images/menubannerbackground.png'

function Menumainbanner() {
  return (
    <div>
        
        <div className='row row-cols-1 menubanner' style={{backgroundImage:`url(${menubgbanner})`}}>
            
            <div className='text-white ' style={{fontFamily:'Gilda Display'}}>
                <p className='menuheader'>NOTRE CARTE</p>
            </div>

        </div>

    </div>
  )
}

export default Menumainbanner