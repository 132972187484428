import React from 'react'
import vegetable from '../Images/vegetable.png'
import widevariety from '../Images/widevariety.png'
import chef from '../Images/chef.png'
import delicious from '../Images/delicious.png'

function Specialitybanner() {
    return (
        <div>
            <h1 className='my-5 text-center'>Pourquoi nous choisir?</h1>
            <div className='row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-4'>
                <div className='col'>
                    <div>
                        <div className="card text-center p-3 border-0 shadow-lg" >
                            <div><img src={vegetable}  className="card-img-top w-50" /></div>
                                <div className="card-body">
                                <h5 className="card-title">Ingredients Frais</h5>
                                </div>
                        </div>
                    </div>
                </div>

                <div className='col'>
                    <div>
                        <div className="card text-center p-3 border-0 shadow-lg">
                            <div><img src={delicious}  className="card-img-top w-50" /></div>
                                <div className="card-body">
                                <h5 className="card-title">Menus très variés</h5>
                                </div>
                        </div>
                    </div>
                </div>

                <div className='col'>
                    <div>
                        <div className="card text-center p-3 border-0 shadow-lg">
                            <div><img src={chef}  className="card-img-top w-50" /></div>
                                <div className="card-body">
                                <h5 className="card-title">Chef Créatif</h5>
                                </div>
                        </div>
                    </div>
                </div>

                <div className='col'>
                    <div>
                        <div className="card text-center p-3 border-0 shadow-lg" >
                            <div><img src={widevariety}  className="card-img-top w-50" /></div>
                                <div className="card-body">
                                    <h5 className="card-title">Délicieux</h5>
                                </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Specialitybanner