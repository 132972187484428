import React from 'react'
import storybanner from '../Images/storybanner.png'
import {Link} from 'react-scroll';

function Storybanner() {
  return (
    <div>
      <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-5'>
        <div className='col'>
          <h1 className='text-center' style={{ fontFamily: 'Italianno', fontSize: '' }}>Notre Histoire</h1>
          <h2 className='text-center fw-bold' style={{ fontFamily: 'Cormorant' }}>Bienvenue à Royal Bangla</h2>
          <p className='lh-lg' style={{ textAlign: 'justify', textAlignLast: 'center', fontFamily: 'Cormorant' }}>Des soupes audacieuses et savoureuses aux entrées croquantes et délicieuses ; aux currys chargés d'épices et de légumes sautés, aux viandes et au poulet tendres mijotés, aux plats de riz rassasiants, la cuisine Indienne de Royal Bangla sert un mélange parfait de goût et de diversité aux végétariens et aux purs amateurs non végétariens ! Notre héritage culinaire séculaire avec un quotient d'épices et un goût incroyables ravira à coup sûr tous les gourmets de la ville. Toute notre cuisine authentique de l'Indienne est saine, fraîche de la ferme et 100 % délicieuse !</p>
          <div className='text-center'>
            <button className='btn px-3 py-2' style={{ border: '1px solid #fc9f1c', color: '#fc9f1c', borderRadius: '0px' }}>
                <Link to='reservation' smooth={true} offset={0} duration={300}>RÉSERVER</Link>
            </button>
          </div>
        </div>
        <div className='col text-center'>
          <div className='mt-4 mt-lg-0'><img src={storybanner} className='storybg' /></div>
        </div>

      </div>
    </div>
  )
}

export default Storybanner