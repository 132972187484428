import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Form, FloatingLabel } from 'react-bootstrap'
import emailjs from '@emailjs/browser'

function Reservation() {
    const { register, handleSubmit } = useForm()
    const form = useRef();

    const onFormSubmit = (details) => {
        emailjs.sendForm('service_nni5xhn', 'template_14kgwwp', form.current, 'z_SZk3E9ffoyP8oNh')
            .then((result) => {
                console.log(result.text);
                console.log('sent')
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div>
            <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>
                {/* Reservation  */}
                <div className='col'>

                    <div className='container p-5'>
                        <p className='text-center'>RÉSERVER UNE TABLE</p>
                        <form ref={form} onSubmit={handleSubmit(onFormSubmit)}>

                            {/* name */}
                            <FloatingLabel style={{ color: 'grey' }} controlId="floatingName" label="Nom" className='mb-3' >
                                <Form.Control type="text" placeholder='Name' style={{ border: '1px solid #B6C5CD', borderRadius: '0px' }} {...register("name", { required: true })} />
                            </FloatingLabel>

                            {/* email */}
                            <FloatingLabel style={{ color: 'grey' }} controlId="floatingInput" label="E-mail" className="mb-3">
                                <Form.Control type="email" placeholder='text@domain.com' style={{ border: '1px solid #B6C5CD', borderRadius: '0px' }} {...register("email", { required: true })} />
                            </FloatingLabel>

                            {/* phone number */}
                            <FloatingLabel style={{ color: 'grey' }} controlId="floatingPassword" label="Mobile" className='mb-3'>
                                <Form.Control type="text" placeholder='Mobile' style={{ border: '1px solid #B6C5CD', borderRadius: '0px' }} {...register("mobile", { required: true })} />
                            </FloatingLabel>

                            {/* date and time  */}
                            <div className='row row-cols-2 mb-3'>
                                <div className='col' style={{ color: 'grey' }}>
                                    <label>Date</label>
                                    <input type="date" lang='fr-CA' className='w-100 form-control p-3' style={{ border: '1px solid #B6C5CD', borderRadius: '0px' }} {...register("date", { required: true })} />

                                </div>
                                <div className='col' style={{ color: 'grey' }}>
                                    <label>Horaire</label>
                                    <input className='w-100 form-control p-3' type="time" style={{ border: '1px solid #B6C5CD', borderRadius: '0px' }} {...register("time", { required: true })} />
                                </div>
                            </div>

                            {/* count  */}
                            <FloatingLabel style={{ color: 'grey' }} controlId="floatingPassword" label="Nombre d'invités" className='mb-3'>
                                <Form.Control type="number" min="0" max="9" placeholder='count' style={{ border: '1px solid #B6C5CD', borderRadius: '0px' }} {...register("count", { required: true })} />
                            </FloatingLabel>

                            {/* message */}
                            <FloatingLabel style={{ color: 'grey' }} controlId="floatingTextarea2" label="Message" className='my-3'>
                                <Form.Control as="textarea" placeholder='Type your message here' style={{ height: '100px', border: '1px solid #B6C5CD', borderRadius: '0px' }} {...register("message", { required: true })} />
                            </FloatingLabel>

                            {/* send */}
                            <button className='text-center btn fw-bold' type='submit' style={{ color: '#fc9f1c', border: '1px solid #fc9f1c' }} >Envoyer</button>
                        </form>
                    </div>
                </div>
                {/* schedule  */}
                <div className='col'>
                    <div className='p-5'>
                        <p className='text-center mb-3'>Horaires</p>

                        <table className="table table-bordered table-striped-columns">

                            <tbody>
                                <tr>
                                    <th>Lundi</th>
                                    <td>12h00 - 14h00</td>
                                    <td>19h00 - 23h00</td>
                                </tr>

                                <tr>
                                    <th>Mardi</th>
                                    <td>12h00 - 14h00</td>
                                    <td>19h00 - 23h00</td>
                                </tr>

                                <tr>
                                    <th>Mercredi</th>
                                    <td>12h00 - 14h00</td>
                                    <td>19h00 - 23h00</td>
                                </tr>

                                <tr>
                                    <th>Jeudi</th>
                                    <td>12h00 - 14h00</td>
                                    <td>19h00 - 23h00</td>
                                </tr>

                                <tr>
                                    <th>Vendredi</th>
                                    <td>12h00 - 14h00</td>
                                    <td>19h00 - 23h00</td>
                                </tr>

                                <tr>
                                    <th>Samedi</th>
                                    <td>12h00 - 14h00</td>
                                    <td>19h00 - 23h00</td>
                                </tr>

                                <tr>
                                    <th>Dimanche</th>
                                    <td>12h00 - 14h00</td>
                                    <td>19h00 - 23h00</td>
                                </tr>

                            </tbody>
                        </table>


                    </div>
                </div>

            </div>
        </div>
    )
}

export default Reservation