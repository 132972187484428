import React from 'react'
import salmoncurry from '../Images/salmoncurry.jpg'
import chickenmasala from '../Images/chickenmasala.jpg'
import muttongravy from '../Images/muttongravy.jpg'

function Ourdishesmenu() {
    return (
        <div>
            <h1 className='text-center my-3' style={{ fontFamily: 'Lemon' }}>NOS PLATS</h1>
            {/* row1 */}
            <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>

                <div className='col p-4'>
                    <div>
                        <div className='row row-cols-2 mt-3' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>POULET CURRY - ORIGINE: KOLKATTA</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet cuit avec du lait de coco, épices mélangées et coriandre fraîche.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}

                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>POULET VINDALOO - ORIGINE: JAIPUR</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet cuit avec des pommes de terre, du lait de coco,des épices mélangées et de coriandre fraîche.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2 ' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>POULET MADRAS - ORIGINE: MADRAS</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet cuit avec une sauce relevée au piment et curry d'origine madras et tomates.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>POULET TIKKA MASSALA - ORIGINE: PUNJAB </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet marinés aux épices mélangées et yaourt,puis grillé et revenu dans une sauce onctueuse à base de tomate, poivrons et coriandre fraîches</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>POULET KORMA - ORIGINE: PUNJAB </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet à la crème et aux épices douces, mélange avec des noix de cajou et des amandes.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,90€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>POULET BUTTER - ORIGINE: RAJASTHAN</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet marinés aux épices mélangées et yaourt,puis grille et préparé dans une sauce à la crème,au beurre, de tomates,noix de cajou et amandes</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,90€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>POULET BAINGAN - ORIGINE: HYDERABAD</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet aux épices mélangées cuit avec des aubergines, des tomates et de la coriandre fraîches</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}

                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>POULET PALAK - ORIGINE: PUNJAB</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet aux épinards finement relève avec des épices mélangées et coriandre fraîches.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2 ' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>GAMBAS CURRY - ORIGINE: GOA </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Gambas décortiquées cuites dans une sauce avec lait de coco et de coriandre fraîches.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>15,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>GAMBAS KORMA - ORIGINE : GOA</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Gambas décortiquées cuites dans une sauce à la crème, aux noix de cajou, aux amandes et citron</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>15,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>AGNEAU PALAK - ORIGINE : PUNJAB</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet à la crème et aux épices douces, mélange avec des noix de cajou et des amandes.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>15,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>AGNEAU ROGAN JOSH - ORIGINE : BOMBAY</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Curry d'agneau aux tomates et au formage avecdes épices mélangées et de la coriandre fraîches</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>15,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>AGNEAU BAINGAN - ORIGINE : HYDERABAD</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Agneau cuisine aux épices mélangées et d'aubergines avec un mélange d'épice et coriandre fraîches</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>15,90€</h5></div>
                        </div>

                    </div>

                </div>
                
                

                <div className='col p-4 pt-3 p-lg-0 pt-lg-0'>
                    <div className='py-3 mt-lg-5 '>
                        <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade menuimg" data-bs-ride="carousel">
                            <div className="carousel-inner menuimg" style={{backgroundColor: 'green', borderRadius: '60px',zIndex:'0' }}>
                                <div className="carousel-item active">
                                    <img src={muttongravy} className="d-block w-100"/>
                                </div>
                                <div className="carousel-item">
                                    <img src={chickenmasala} className="d-block w-100" />
                                </div>
                                <div className="carousel-item">
                                    <img src={salmoncurry} className="d-block w-100" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row row-cols-2 mt-4 ' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9 col-lg-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>AGNEAU KORMA - ORIGINE : PUNJAB </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Agneau cuit dans une sauce à la crème, noix de cajou et amandes et épices douces</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>15,90€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>AGNEAU MADRAS - ORIGINE : MADRAS</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Curry d'agneau cuit dans un mélange d'épice relevés et de coriandre fraîches</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>15,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>AGNEAU VINDALOO - ORIGINE : GOA </h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Agneau cuit avec de pommes de terre des d'épice mélangées et de la coriandre fraîches</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>15,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}

                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>BŒUF BHUNA - ORIGINE : KERALA</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Curry de Bœuf cuit avec des épices mélangées et de coriandre fraîches.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2 ' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>BŒUF MADRAS - ORIGINE : MADRAS</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Curry d'boeuf cuit dans un mélange d'épice relevés et de coriandre fraîches.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>BŒUF VINDALOO - ORIGINE : BOMBAY</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Curry de Bœuf et pommes de terre cuit avec des épices mélangées et de la coriandre fraîches</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>14,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>SAUMON CURRY - ORIGINE : GOA</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Fille de saumon cuits dans une sauce au lait de coco avec Gingembre, épices mélangées et coriandre fraîches.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>16,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>SAUMON MADRAS - ORIGINE : MADRAS</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Poulet cuit avec une sauce relevée au piment et curry d'origine madras et tomates.</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>16,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-4' style={{ color: 'grey' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px', borderBottom: '2px solid #fc9f1c' }}>
                                <h5 className='fw-bold'>SAUMON KORMA - ORIGINE : BOMBAY</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Fille de saumon cuits une sauce à la crème avec des noix de cajou des amandes et épices douces</p>
                            </div>
                            {/* price */}
                            <div className='col-3 col-lg-2' style={{ borderBottom: '2px solid #fc9f1c' }}><h5 className='text-end fw-bold'>16,50€</h5></div>
                        </div>



                </div>

            </div>
            

        </div>
    )
}

export default Ourdishesmenu