import React from 'react'
import gongrabiryani from '../Images/biryani.jpg'

function Ourbiryanidishes() {
  return (
    <div className='container'>
            <h1 className='text-center pt-5' style={{ fontFamily: 'Lemon' }}>NOS BRIYANIS</h1>
            <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>
                <div className='col text-center'>
                    <div className='my-5 pt-1'>
                        <img src={gongrabiryani} width='100%' />
                    </div>
                </div>
                <div className='col'>

                    <div className='p-4 my-5' style={{border:'2px solid black'}}>

                        <div className='row row-cols-2 ' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>LEGUMES BRIYANI</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Base de riz basmati, des Legumes, mélange de fruits exotiques, épices mélangées, coriandre fraiche et safron, accompagné de raita nature.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>12,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>POULET BRIYANI</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Base de riz basmati, Poulet, mélange de fruits exotiques, épices mélangées, coriandre fraiche et safron, accompagné de raita nature</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>14,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>BŒUF BRIYANI</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Base de riz basmati, des Bœuf, mélange de fruits exotiques, épices mélangées, coriandre fraiche et safron, accompagné de raita nature.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>14,90€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>AGNEAU BRIYANI</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Base de riz basmati, Agneau, mélange de fruits exotiques, épices mélangées, coriandre fraiche et safron, accompagné de raita nature</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>15,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>MIX BRIYANI</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Base de riz basmati, mélange des 3 viandes, mélange de fruits exotiques, épices mélangées, coriandre fraiche et safron, accompagné de raita nature.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>16,50€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>GAMBAS BRIYANI</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Base de riz basmati, Gambas décortiquées, mélange de fruits exotiques, épices mélangées, coriandre fraiche et safron, accompagné de raita nature.</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>15,50€</h5></div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
  )
}

export default Ourbiryanidishes