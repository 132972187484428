import React from 'react'
import Corouselbar from './Corouselbar'
import Specialitybanner from './Specialitybanner'
import Storybanner from './Storybanner'
import Footer from './Footer'
import Animatedhomebanner from './Animatedhomebanner'
import Reservation from './Reservation'
import Gallery from './Gallery'

import { Link } from 'react-scroll';


function Home() {
  return (
    <div>
      {/* Home banner */}
      <div >
        <Animatedhomebanner />
      </div>

      {/* storybanner  */}
      <div className='container-lg' >
        <Storybanner />
      </div>


      {/* corousel */}
      <div className='mt-md-5 container' >
        <Corouselbar />
      </div>

      {/* gallery */}
      <div className='container mt-1'>
        <Gallery />
      </div>

      {/* Specialitybanner */}
      <div className='my-5 container'>
        <Specialitybanner />
      </div>

      {/* Reservation and Schedule */}
      <div id='reservation'>
        <Reservation />
      </div>

      {/* back to top  */}
      <div className='d-flex justify-content-end me-3 pb-3' style={{ position: 'sticky', bottom: '5px' }} >
        <button className='btn p-3' style={{ color: 'orange', borderRadius: '50%', border: '1px solid orange' }}>
          <Link to='navtop' smooth={true} offset={0} duration={300}><i className="fa-regular fa-circle-up fa-bounce fa-2xl"></i></Link>
        </button>
      </div>

      {/* footer  */}
      <div>
        <Footer />
      </div>

    </div>
  )
}

export default Home