import React from 'react'

function Combo() {
  return (
    <div className='container text-center p-md-5 p-sm-2' style={{backgroundColor:'#FFD580', width:'90%'}}>
        <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2' style={{border:'4px double black' }} >
            
                <div className='col py-md-5 mt-sm-3 mt-lg-0'>
                    <h1 className='pt-3 pt-sm-0' style={{fontFamily:'Gilda Display'}}>FORMULES CLASSIQUE</h1>
                    <div className='row row-cols-2 row-cols-sm-2 row-cols-md-2 '>
                        <div className='col'>
                            <h3 className='my-4 combohead' >MENU À 19,90€</h3>
                            <p>PAKORAS / DAAL SOUPE</p>
                            <p style={{ lineHeight: '5px' }}>-----</p>
                            <p >POULET CURRY / PLAT VÉGÉTARIEN</p>
                            <p style={{ lineHeight: '5px' }}>-----</p>
                            <p >GULAB JAMUN / KHEER</p>
                        </div>

                        <div className='col'>
                            <h3 className='my-4 combohead'>MENU À 22,90€</h3>
                            <p>ENTRÉE AU CHOIX</p>
                            <p className='mb-1' style={{ lineHeight: '5px' }}>-----</p>
                            <p className='my-2' >POULET TIKKA MASSALA / <br/> BOEUF BHUNA / <br/> GAMBAS CURRY</p>
                            <p style={{ lineHeight: '5px' }}>-----</p>
                            <p >DESSERT AU CHOIX</p>
                        </div>
                        
                        
                    </div>
                </div>
                <div className='col p-5'>
                    <h1 style={{fontFamily:'Gilda Display'}}>FORMULES MIDI</h1>
                    <p className='mt-5'>ENTRÉE + PLAT + DESSERT : 14.90€</p>
                    <p style={{ lineHeight: '5px' }}>-----</p>
                    <p>ENTRÉE + PLAT / PLAT + DESSERT : 12.90€</p>
                    <p style={{ lineHeight: '5px' }}>-----</p>
                    <p>PLAT DU JOUR : 10€</p>
                </div>
            </div>
        
    </div>
  )
}

export default Combo