import React from 'react'
import paneer from '../Images/paneer.jpg'

function Ourvegetarianmenu() {
    return (
        <div className='container'>
            <h1 className='text-center pt-5' style={{ fontFamily: 'Lemon' }}>NOS PLATS VÉGÉTARIENS</h1>
            <div className='row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2'>
                <div className='col text-center'>
                    <div className='my-5 pt-1'>
                        <img src={paneer} width='80%' />
                    </div>
                </div>
                <div className='col'>

                    <div className='p-4 my-5' style={{border:'2px solid black'}}>

                        <div className='row row-cols-2 ' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>BAINGAN BHURTA</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Fondue d'aubergines cuites dans une sauce à la crème,aux épices mélangées avec de la coriandre fraîches</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>12,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>SAAG ALOO</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Curry de légumes (tomates, pommes de terre )préparé dans une sauce au lait de coco épices mélangées et coriandre fraîches</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>12,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>PALAK PANEER</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Curry d'épinards et fromages préparé dans une sauce aux noix de cajou, amandes et épices douces</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>12,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ borderBottom: '2px solid black', color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>ALOO MUTTER</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Curry de légumes (tomates, pommes de terre )préparé dans une sauce. au lait de coco épices mélangées et coriandre</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>12,00€</h5></div>
                        </div>

                        <div className='row row-cols-2 mt-3' style={{ color: 'black' }}>
                            {/* dish & description*/}
                            <div className='col-9' style={{ lineHeight: '5px' }}>
                                <h5 className='fw-bold'>MATAR PANEER</h5>
                                <p style={{ fontSize: '14px', lineHeight: '15px' }}>Curry de petits pois avec du fromage, cuit dans une sauce avec des amandes</p>
                            </div>
                            {/* price */}
                            <div className='col-3'><h5 className='text-center fw-bold'>12,00€</h5></div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default Ourvegetarianmenu